import PropTypes from 'prop-types';
import React from 'react';

import propTypes from '../propTypes';
import { fieldComparator } from '../utils';
import PageLink from './PageLink';

const groupPostsByYear = posts => {
  const groupedPosts = {};

  posts.forEach(post => {
    const year = new Date(post.date).getFullYear().toString();
    if (!(year in groupedPosts)) {
      groupedPosts[year] = { year, posts: [] };
    }
    groupedPosts[year].posts.push(post);
  });
  return Object.values(groupedPosts).sort(fieldComparator('year', { order: 'desc' }));
};

const PostList = ({ posts, byYear }) => {
  if (byYear) {
    const postsByYear = groupPostsByYear(posts);

    return (
      <>
        {postsByYear.map(({ year, posts: annualPosts }) => (
          <div key={year} className="page-link-group">
            <h2>{year}</h2>
            {annualPosts.map(post => (
              <PageLink key={post.slug} {...post} />
            ))}
          </div>
        ))}
      </>
    );
  }

  return posts.map(post => <PageLink key={post.slug} {...post} />);
};

PostList.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      icon: propTypes.icon,
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
  byYear: PropTypes.bool,
};

PostList.defaultProps = {
  posts: [],
  byYear: true,
};

export default PostList;
