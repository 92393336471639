import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Layout from '../../components/Layout';
import PostList from '../../components/PostList';
import { filenameToSlug, mdxToEntities } from '../../helpers';

const BlogPage = ({ data }) => {
  const posts = mdxToEntities(data.posts);

  return (
    <Layout title="Blog" slug={filenameToSlug(__filename)}>
      <div className="content-list">
        <h1>Blog</h1>
        <PostList posts={posts} />
      </div>
    </Layout>
  );
};

BlogPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default BlogPage;

export const blogPageQuery = graphql`
  query BlogPage {
    posts: allMdx(
      filter: { frontmatter: { draft: { ne: true }, template: { eq: "post" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      ...Posts
    }
  }
`;
